.h-full .freeprivacypolicy-com---palette-light.freeprivacypolicy-com---nb {
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.h-full .freeprivacypolicy-com---palette-light .cc-nb-okagree,
.h-full .freeprivacypolicy-com---palette-light .cc-nb-reject{
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.h-full .freeprivacypolicy-com---palette-light .cc-nb-okagree,
.h-full .freeprivacypolicy-com---palette-light .cc-nb-reject,
.h-full .freeprivacypolicy-com---palette-light .cc-nb-changep{
    font-weight: 500;
    border-radius: 4px;
}